






























import Vue from "vue";
import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";
export default Vue.use(VueSocketIOExt, io("https://circles-api.hyunwoo.dev/")).extend({
	sockets: {
		interview_getInterviewByClubName(this: any, data) {
			this.$store.commit("clearLoading", "interview_getInterviewByClubName");
			this.isStart = data.result;
			if (data.result) this.interviewers = data.data.interviewers;
		},
		interview_startInterview(this: any, data) {
			if (data.result) this.interviewers = data.data.interviewers;
			this.isStart = data.result;
		},
		interview_closeInterview(this: any, data) {
			if (data.result) this.interviewers = [];
			this.isStart = !data.result;
		},
		interview_updateInterviewers(this: any, data) {
			this.interviewers = data.data.interviewers;
		},
	},
	data() {
		return {
			isStart: false,
			interviewers: [],
		};
	},
	created() {
		this.$store.commit("pushLoading", {
			name: "interview_getInterviewByClubName",
			message: "면접 불러오는 중",
		});
		this.$socket.client.emit("interview_getInterviewByClubName", {
			clubname: this.getClub.name,
		});
	},
	methods: {},
	computed: {
		getFirst(): any {
			return this.interviewers[0] || { name: "-", number: "-" };
		},
		getSecond(): any {
			return this.interviewers[1] || { name: "-", number: "-" };
		},
		getClub() {
			return this.$store.state.club;
		},
	},
});
